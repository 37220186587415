/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    ol: "ol",
    h3: "h3",
    strong: "strong",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Payroc API is a RESTful API that includes the following properties:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Resource-based URLs"), "\n", React.createElement(_components.li, null, "Form-encoded requests"), "\n", React.createElement(_components.li, null, "JSON responses"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/versioning",
    title: "Versioning"
  }, "AGILE versioning")), "\n"), "\n", React.createElement(_components.h2, null, "Your integration journey"), "\n", React.createElement(_components.p, null, "To help you integrate with our solutions, we provide you with access to a dedicated integration support team, developer documentation, and a personalized integration journey that consists of the following six stages:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#discovery-call"
  }, "Discovery call")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#statement-of-work"
  }, "Statement of work")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#development"
  }, "Development")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#validation"
  }, "Validation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#go-live"
  }, "Go live")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#ongoing-support"
  }, "Ongoing support")), "\n"), "\n", React.createElement(_components.h3, null, "Discovery call"), "\n", React.createElement(_components.p, null, "Our Sales Engineering team contact you to discuss your requirements and to help identify the solutions that best fit your needs. For example, we provide solutions that you can use to run card-present transactions, deploy hosted payment pages, and accept bank transfer payments."), "\n", React.createElement(_components.p, null, "For more information about our solutions, go to ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/guides/features-and-functions"
  }, "API Features and Functions"), "."), "\n", React.createElement(_components.h3, null, "Statement of work"), "\n", React.createElement(_components.p, null, "Based on your requirements, we create a statement of work that describes the solutions that you need to integrate with."), "\n", React.createElement(_components.h3, null, "Development"), "\n", React.createElement(_components.p, null, "Before you integrate with the solutions in your statement of work, read through our documentation, which includes ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/guides/integrate"
  }, "guides"), " that describe how to integrate with key functions of our API."), "\n", React.createElement(_components.p, null, "If you have any questions during the development stage, contact our Integration Engineers."), "\n", React.createElement(_components.h3, null, "Validation"), "\n", React.createElement(_components.p, null, "After you complete the development stage, you need to test that your integration works. We provide you with test cases and sandboxes to test your integration with real-life scenarios."), "\n", React.createElement(_components.h3, null, "Go live"), "\n", React.createElement(_components.p, null, "After we validate your integration, you can run live requests. We monitor your integration for the first few weeks to check that everything is working correctly."), "\n", React.createElement(_components.h3, null, "Ongoing support"), "\n", React.createElement(_components.p, null, "After your integration is live, contact our Customer Support team if you have any questions."), "\n", React.createElement(_components.h2, null, "Features"), "\n", React.createElement(_components.p, null, "To help you use our API, we include the following features:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Pagination:"), " For responses that contain many results, we provide ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/pagination",
    title: "Pagination"
  }, "pagination"), " to help you navigate the results and increase the performance of your integration."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Metadata:"), " Use ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/metadata",
    title: "Metadata"
  }, "metadata"), " to add custom data to your API entities."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "HATEOAS:"), " Our responses include HATEOAS links that you can use to run follow-on actions on resources."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Idempotency:"), " We use ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/idempotency",
    title: "Idempotency"
  }, "idempotency keys"), " to prevent duplicate PATCH and POST requests from causing unwanted issues."), "\n", React.createElement(_components.h2, null, "Base URL"), "\n", React.createElement(_components.p, null, "The base URL of the Payroc API is ", React.createElement(_components.code, null, "https://api.payroc.com/"), "."), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.p, null, "Before you begin, make sure that you have contacted our Sales Team to set up your account, and that you have received your API key."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "If you haven't set up your account, ", React.createElement(_components.a, {
    href: "https://payroc.com/contacts/partner-with-us",
    title: "Partner with us"
  }, "contact our Sales Team"), ".")), "\n", React.createElement(_components.h2, null, "Integration key tasks"), "\n", React.createElement(_components.p, null, "To integrate with our API, you need to write code to complete the following key tasks:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Generate ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/authentication",
    title: "Authentication"
  }, "Bearer tokens"), "."), "\n", React.createElement(_components.li, null, "Create requests."), "\n", React.createElement(_components.li, null, "Handle responses and errors."), "\n", React.createElement(_components.li, null, "Test your integration."), "\n"), "\n", React.createElement(_components.p, null, "To start integrating with our API, read one of our ", React.createElement(_components.a, {
    href: "/guides/integrate",
    title: "Integration Guides"
  }, "Integration Guides"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
